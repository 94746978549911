export const CardIcon = <img src="https://img.icons8.com/?size=100&id=49139&format=png&color=000000" width={37} height={37}/>
export const PetIcon = <img src="https://img.icons8.com/?size=100&id=106514&format=png&color=000000" width={37} height={37}/>
export const DiceIcon = <img src="https://img.icons8.com/?size=100&id=569&format=png&color=000000" width={37} height={37}/>
export const QuestionIcon = <img src="https://img.icons8.com/?size=100&id=98973&format=png&color=b4bad4" width={37} height={37}/>
export const RocketIcon = <img src="https://img.icons8.com/?size=100&id=999&format=png&color=b4bad4" width={34} height={34}/>
export const ExplosionIcon = <img src="https://img.icons8.com/?size=100&id=SLx9kZV_N4bX&format=png&color=000000" width={100} height={100}/>
export const LeaderboardIcon = <img src="https://img.icons8.com/?size=100&id=6yiQUAER3NXc&format=png&color=b4bad4" width={35} height={35}/>
export const ProfileIcon = <img src="https://img.icons8.com/?size=100&id=fUUEbUbXhzOA&format=png&color=b4bad4" width={35} height={35}/>
export const PlinkoIcon = <img src="https://img.icons8.com/?size=100&id=mIzQr2yyVyPW&format=png&color=b4bad4" width={35} height={35}/>
export const WhiteboardIcon = <img src="https://img.icons8.com/?size=100&id=Oyhn1OzMgr68&format=png&color=000000" width={35} height={35}/>
export const FeedbackIcon = <img src="https://img.icons8.com/?size=100&id=52209&format=png&color=b4bad4" width={35} height={35}/>
export const ReferralIcon = <img src="https://img.icons8.com/?size=100&id=58565&format=png&color=b4bad4" width={35} height={35}/>