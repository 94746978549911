import "./Footer.css";

const Footer = () => {  
    return (
      <div className="footerBox">
        © Hyrax Solutions | Copyright 2024 | All rights reserved
      </div>
    );
};
export default Footer;
  