import userPic from '../assets/user.png';
import mainLogo from '../assets/mainLogo.png'
import coinIcon from '../assets/coin-icon.png'
import ticketIcon from '../assets/ticket-icon.png'
import whiteboardIcon from '../assets/WhiteboardIcon.png';

export default{
    userPic,
    mainLogo,
    coinIcon,
    ticketIcon,
    whiteboardIcon,
}