import "./Footer2.css";

const Footer2 = () => {  
    return (
      <div className="footerBox2">
        © Hyrax Solutions | Copyright 2024 | All rights reserved
      </div>
    );
};
export default Footer2;
  